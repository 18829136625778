<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <v-skeleton-loader
        type="chip"
        transition="fade-transition"
        v-if="initialLoadingPage"
      ></v-skeleton-loader>

      <v-toolbar-title v-else class="headline font-weight-bold">
        Reports ({{ listMeta.total }})
      </v-toolbar-title>
    </v-app-bar>

    <div class="mt-2 mx-4" id="user-list">
      <v-simple-table>
        <thead v-show="!initialLoadingPage">
          <tr>
            <th
              width="10%"
              class="text-left"
              @click="sortColumn"
              data-column="report_type"
            >
              Report Type
              <v-icon v-if="params.sort_by == `report_type`">{{
                icons.selectedArrow
              }}</v-icon>
            </th>
            <th
              width="10%"
              class="text-left"
              @click="sortColumn"
              data-column="reporter"
            >
              Reporter
              <v-icon v-if="params.sort_by == `reporter`">{{
                icons.selectedArrow
              }}</v-icon>
            </th>
            <th width="10%" class="text-left">Category</th>
            <th width="10%" class="text-left" data-column="description">
              Description
            </th>
            <th width="20%" class="text-left">Reported</th>
            <th
              width="10%"
              class="text-left"
              @click="sortColumn"
              data-column="created_at"
            >
              Added date
              <v-icon v-if="params.sort_by == `created_at`">{{
                icons.selectedArrow
              }}</v-icon>
            </th>
            <th width="10%">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-show="!loading" v-for="report in reports" :key="report.id">
            <td class="text-capitalize">{{ report.report_type }}</td>
            <td class="p-0">
              <router-link
                :to="{
                  name: 'user.details',
                  params: { id: report.reporter.id },
                }"
                >{{ report.reporter.full_name }}</router-link
              >
            </td>
            <td>{{ report.reason ? report.reason.label : 'N/A' }}</td>

            <td class="text-truncate" style="max-width: 300px">
              {{ report.description || 'N/A' }}
            </td>
            <td>
              <span v-if="report.report_type === 'users'">
                <strong>User: </strong>

                <router-link
                  :to="{
                    name: 'user.details',
                    params: { id: report.reportable.id },
                  }"
                  >{{ report.reportable.full_name }}</router-link
                >
              </span>
              <span v-else-if="report.report_type === 'bookings'">
                <!-- to do: booking details page; redirect to booking details -->
                <router-link
                  :to="{
                    name: 'booking.edit',
                    params: { id: report.reportable.id },
                  }"
                  >{{ report.reportable.listing.title }}</router-link
                >
              </span>
              <span v-else-if="report.report_type === 'listings'">
                <router-link
                  :to="{
                    name: 'listing.edit',
                    params: { id: report.reportable.id },
                  }"
                  >{{ report.reportable.title }}
                </router-link>
              </span>
              <span v-else-if="report.report_type === 'reviews'">
                <router-link
                  v-if="report.reportable.reviewable_type === 'bookings'"
                  :to="{
                    name: 'booking.edit',
                    params: { id: report.reportable.reviewable.id },
                  }"
                  >{{ report.reportable.title }}
                </router-link>
                <router-link
                  v-else-if="report.reportable.reviewable_type === 'listings'"
                  :to="{
                    name: 'listing.edit',
                    params: { id: report.reportable.reviewable.id },
                  }"
                  >{{ report.reportable.title }}
                </router-link>
              </span>
            </td>
            <td class="white_space">{{ report.addedDate }}</td>
            <td
              width="10%"
              class="clickable"
              @click="
                $router.push({
                  name: 'reports.details',
                  params: { id: report.id },
                })
              "
            >
              <v-icon>{{ icons.viewList }}</v-icon>
            </td>
          </tr>

          <template v-if="initialLoadingPage || loading">
            <tr v-for="index in params.limit" :key="index + '-skeleton'">
              <td class="pl-0">
                <v-skeleton-loader
                  type="list-item"
                  transition="fade-transition"
                />
              </td>
              <td class="pl-0">
                <v-skeleton-loader
                  type="list-item"
                  transition="fade-transition"
                />
              </td>
              <td class="pl-0">
                <v-skeleton-loader
                  type="list-item"
                  transition="fade-transition"
                />
              </td>
              <td class="pl-0">
                <v-skeleton-loader
                  type="list-item"
                  transition="fade-transition"
                />
              </td>
              <td class="pl-0">
                <v-skeleton-loader
                  type="list-item"
                  transition="fade-transition"
                />
              </td>
              <td class="pl-0">
                <v-skeleton-loader
                  type="list-item"
                  transition="fade-transition"
                />
              </td>
              <td class="pl-0">
                <v-skeleton-loader
                  type="list-item"
                  transition="fade-transition"
                />
              </td>
            </tr>
          </template>
        </tbody>
      </v-simple-table>
    </div>

    <v-snackbar v-model="snackbar.show" :color="snackbar.color" right>
      {{ snackbar.message }}
      <v-btn color="white" text @click="snackbar.show = false">Ok</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import {
  mdiDotsVertical,
  mdiUnfoldMoreHorizontal,
  mdiMagnify,
  mdiAccountCircleOutline,
  mdiChevronRight,
  mdiFileExcel,
  mdiArrowUpBold,
  mdiArrowDownBold,
  mdiEye,
} from '@mdi/js'
import ControlsMixin from '@/utils/mixins/Controls'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import infiniteScroll from 'vue-infinite-scroll'
import debounce from 'lodash/debounce'

export default {
  name: 'ReportsPage',

  mixins: [ControlsMixin],

  directives: {
    infiniteScroll,
  },

  components: {
    AppBarNavIcon,
  },

  data() {
    return {
      loading: false,
      initialLoadingPage: true,
      isLoadingPagination: false,
      showSearch: false,
      showProfile: false,
      selectedProfile: null,
      filter: {
        search: '',
      },
      icons: {
        settings: mdiDotsVertical,
        sort: mdiUnfoldMoreHorizontal,
        search: mdiMagnify,
        view: mdiAccountCircleOutline,
        arrow: mdiChevronRight,
        export: mdiFileExcel,
        arrowUp: mdiArrowUpBold,
        arrowDown: mdiArrowDownBold,
        selectedArrow: mdiArrowUpBold,
        viewList: mdiEye,
      },
      snackbar: {
        show: false,
        message: null,
        color: null,
      },
      params: {
        sort_direction: 'asc',
        sort_by: null,
        limit: 10,
      },
      page: 1,
    }
  },

  created() {
    this.clearReports()
    this.fetchReports()
  },

  computed: {
    ...mapState({
      reports: (state) => state.report.list,
      listMeta: (state) => state.report.listMeta,
    }),

    paginationLength() {
      return (this.listMeta.total / this.listMeta.per_page) | 0
    },
  },

  methods: {
    ...mapActions({
      getReports: 'report/getReports',
    }),

    ...mapMutations({
      clearReports: 'report/clearReportsList',
    }),

    pageChanged(page) {
      this.fetchReports(page)
    },
    onSelectItem() {
      this.clearReports()
      this.fetchReports(1)
    },
    sortColumn(e) {
      this.params.sort_direction =
        this.params.sort_direction === 'asc' ? 'desc' : 'asc'

      this.icons.selectedArrow =
        this.params.sort_direction === 'asc' ? mdiArrowUpBold : mdiArrowDownBold

      this.clearReports()
      this.params.sort_by = e.target.getAttribute('data-column')
      this.fetchReports()
    },
    search: debounce(function () {
      this.clearReports()
      this.fetchReports()
    }, 600),

    async fetchReports(page = 1) {
      if (this.loading) return

      let params = this.params
      params.page = page

      if (this.filter.search) {
        params.search = this.filter.search
      } else {
        params.search = ''
      }

      if (!this.initialLoadingPage) {
        this.loading = true
      } else {
        this.isLoadingPagination = true
      }

      await this.getReports(params)

      if (!this.initialLoadingPage) {
        this.loading = false
      } else {
        this.isLoadingPagination = false
      }

      this.initialLoadingPage = false
    },

    showSnackbar(message, color) {
      this.snackbar.message = message
      this.snackbar.color = color
      this.snackbar.show = true
    },

    async showSearchField() {
      this.showSearch = !this.showSearch
      await this.$nextTick()
      this.$refs.searchField.$refs.input.focus()
    },
  },
  watch: {
    page() {
      this.pageChanged(this.page)
    },
  },
}
</script>
<style lang="css" scoped>
@media (max-width: 600px) {
  .white_space {
    white-space: nowrap;
  }
}
</style>
